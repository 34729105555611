import api from './api'
export default {
  allUsersList() {
    return api.execute('get', `/manager/users`)
  },
  updateUser(data) {
    return api.execute('put', '/manager/users', data)
  },
  sendForgotPasswordMail(data) {
    return api.execute('post', '/forgetpwd', data)
  },
  changePassword(data) {
    return api.execute('post', '/changepwd', data)
  },
  authenticate(){
    return api.execute(`get`,`/alive`)
  },
}