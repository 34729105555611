<template>
  <div>
    <b-modal id="openInsightDetail" title="Insight Service Test" size="xl">
      <b-card class="custom-modal-card-2">
        <b-row>
          <b-col>
            <label class=""
              >Select Service <span style="color: red">*</span>
            </label>
            <multiselect
              class=""
              :options="serviceList"
              label="title"
              v-model="selectedService"
              placeholder="Select Service"
              required
            ></multiselect>
          </b-col>
          <b-col>
            <label class="">Country <span style="color: red">*</span> </label>
            <multiselect
              :options="countryOpt"
              label="name"
              track-by="name"
              v-model="selectedCountry"
              placeholder="Select Country"
              @input="changeNetworkType"
              @remove="removeNetworkType"
            >
            </multiselect>
          </b-col>

          <b-col>
            <label class=""
              >Network Type <span style="color: red">*</span>
            </label>
            <multiselect
              class=""
              :options="networkTypeArray"
              label="name"
              track-by="id"
              v-model="selectedNetworkType"
              placeholder="Select Network Type"
            >
            </multiselect>
          </b-col>
        </b-row>
        <div class="">
          <b-row>
            <b-col md="12" class="mt-2">
              <label>Banner Found URL</label>
              <b-input
                type="text"
                label="Banner Found URL"
                placeholder="Banner Found Url"
                v-model="basicInfo.bannerFoundUrl"
              >
              </b-input>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <label class="mt-2"
                >Final URL <span style="color: red">*</span>
              </label>
              <b-input
                type="text"
                label="Final URL"
                placeholder="Final Url"
                v-model="basicInfo.finalUrl"
              >
              </b-input>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-row v-show="insight_id">

      <add-insight-step :insight_id="insight_id" :isEdit="isEdit"></add-insight-step>

        <div class="">
          <b-card no-body>
            <b-tabs pills card>
              <b-tab title="Hosts" active>
                <b-card-text>
                  <span v-if="hosts.length >= 0">
                    <li v-for="(item, key) in hosts" :key="key">
                      {{ item }} -
                      <span
                        class="link_txt text-danger"
                        @click="deleteHost(key)"
                        >Delete</span
                      >
                    </li>
                  </span>
                </b-card-text>
                <b-row>
                  <span class="mt-2 text-secondary" style="cursor: pointer;">
                    <feather type="info" title="Example: xyz.com, abc.in"></feather>
                  </span>
                  <b-col class="col-md-9">
                    <b-form-input
                      type="text"
                      label="Hosts"
                      placeholder="Please enter comma separated hosts"
                      v-model="InputHost"
                      v-on:keyup.enter="AddHosts()"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="AddHosts()"
                      >Add Host</b-button
                    >
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="URL Chain">
                <b-card-text>
                  <span v-if="urlChain.length >= 0">
                    <li v-for="(item, key) in urlChain" :key="key">
                      {{ item }} -
                      <span
                        class="link_txt text-danger"
                        @click="deleteUrlChain(key)"
                        >Delete</span
                      >
                    </li>
                  </span>
                </b-card-text>
                <b-row>
                  <span class="mt-2 text-secondary" style="cursor: pointer;">
                    <feather type="info" title="Example: https://xyz.com, http://abc.in"></feather>
                  </span>
                  <b-col class="col-md-9">
                    <b-input
                      type="text"
                      label="URL Chain"
                      placeholder="Please enter comma separated urls"
                      v-model="InputUrlChain"
                      v-on:keyup.enter="AddUrlChain()"
                    >
                    </b-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="AddUrlChain()"
                      >Add URL</b-button
                    >
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Log">
                <b-card-text>
                  <span v-if="logs.length >= 0">
                    <li v-for="(item, key) in logs" :key="key">
                      {{ item }} -
                      <span
                        class="link_txt text-danger"
                        @click="deleteLogs(key)"
                        >Delete</span
                      >
                    </li>
                  </span>
                </b-card-text>
                <b-row>
                  <b-col class="col-md-10">
                    <b-input
                      type="text"
                      label="Logs"
                      placeholder="input logs to add"
                      v-model="InputLogs"
                      v-on:keyup.enter="AddLogs()"
                    >
                    </b-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="AddLogs()"
                      >Add Logs</b-button
                    >
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
        <hr class="my-2" />
        <b-row>
          <b-col class="md-0">
           
          <b-form class="mega-inline">
								<b-form-group class="mb-0">
								<div class="row">

                  <div class="col-sm-4">
										<div class="card border-0">
											<div class="media p-20">
												<div class="radio radio-primary">
													<b-form-radio name="radio1" v-model="status" value="1">
														<div class="media-body">
															<h6 class="mt-0 mega-title-badge">Pending<span class="badge badge-primary pull-right digits"></span></h6>
														
														</div>
													</b-form-radio>
												</div>
											</div>
										</div>
									</div>
									
									<div class="col-sm-4">
										<div class="card border-0">
											<div class="media p-20">
												<div class="radio radio-success">
													<b-form-radio name="radio1" v-model="status" value="2">
														<div class="media-body">
															<h6 class="mt-0 mega-title-badge">Approve<span class="badge badge-success pull-right digits"></span></h6>
														
														</div>
													</b-form-radio>
												</div>
											</div>
										</div>
									</div>

									<div class="col-sm-4">
										<div class="card border-0">
											<div class="media p-20">
												<div class="radio radio-secondary">
													<b-form-radio name="radio1" v-model="status" value="3">
														<div class="media-body">
															<h6 class="mt-0 mega-title-badge">Reject<span class="badge badge-secondary pull-right digits"></span></h6>
															
														</div>
													</b-form-radio>
												</div>
											</div>
										</div>
									</div>

								</div>
								</b-form-group>
					</b-form>
          </b-col>
        </b-row>

      </b-row>
      <b-row>
        <b-card class="text-right mr-4 mt-0">
          <b-button
            variant="primary"
            v-show="!insight_id"
            @click="saveBasicInfo"
            >Save & Next</b-button
          >
        </b-card>
      </b-row>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            class="float-right mr-2"
            @click="changeTrxDetail()"
            :disabled="disable_submit"
          >
            {{ isEdit ? "Save" : "Add" }}
          </b-button>
          <b-button class="float-right mr-2" @click="closeModal()">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ServiceInfoApi from "../../../../services/ServiceInfo";
import NetworkTypeService from "../../../../services/NetworkTypeService";
import Multiselect from "vue-multiselect";
import config from "../../../../services/globalLit";
import InsightService from "../../../../services/InsightService";
import addInsightStep from "../../../components/addInsightStep.vue";

export default {
  components: {
    Multiselect,
    addInsightStep,
  },
  beforeDestroy() {
    this.is_destroying = true;
  },
  destroyed() {
    this.is_destroying = false;
  },
  data() {
    return {
    statusOption: [
      {
        text: 'Pending',
        value: 1
      },
      {
      text: 'Approve',
      value: 2
      }, 
      {
      text: 'Reject',
      value: 3
      }
    ],
      status: 0,
      file: null,
      basicInfo: {
        country: null,
        netType: null,
        sid: null,
        bannerFoundUrl: null,
        finalUrl: null,
    },
      file1: null,
      videoUrl: null,
      stepUploadUrl: config.uploadStepImage,
      insight_id: null,
      basicInfo: {},
      is_destroying: false,
      multidropzoneOptions: {
        // url: "/",

        destroyDropzone: false,
        defaultHeaders: true,
        withCredentials: true,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        // uploadMultiple:true,
        createImageThumbnails: true,
        dictRemoveFileConfirmation: 'Are you sure to delete this image?',
        maxFilesize: 2,
        maxFiles: 12,
        addRemoveLinks: true,
        acceptedFiles: "image/jpeg,image/png",
        dictDefaultMessage:
          "<i class='icon-cloud-up'></i><h6>Drop files here or click to upload.</h6>",
      },
      tabs: [],
      tabCounter: 0,
      selectedTitle: null,
      activeTab: null, // To track the active tab
      tabTitles: [
        { value: null, text: "Choose a title" },
        { value: "Home Page", text: "Home Page" },
        {value: 'Consent', text: 'Consent'},
        {value: 'Ad Page', text: 'Ad Page'},
        {value: 'Ad Page1', text: 'Ad Page1'},
         {value: 'Ad Page2', text: 'Ad Page2'},
        { value: "MSISDN Entry", text: "MSISDN Entry" },
        { value: "PIN Message", text: "PIN Message" },
        { value: "PIN Page", text: "PIN Page" },
        {
          value: "Subscription Confirm Message",
          text: "Subscription Confirm Message",
        },
        { value: "Content", text: "Content" },
        { value: "Cancel Subscription", text: "Cancel Subscription" },
        {
          value: "Unsubscription Confirm Message",
          text: "Unsubscription Confirm Message",
        },
        { value: "Video", text: "Video" },
      ],
      landingImgLimit: 12,
      imagePreviewUrls: [],
      images: [null],
      img: "",
      spModal: false,
      smModal: false,
      add_service_modal: false,
      disable_submit: false,
      bannerImg: "",
      landingImg: "",
      landingImgs: [],
      imageOptions: [],
      imageValue: [],
      adImg: "",
      screenshot: {},
      selectedNetworkType: {},
      chennelArray: config.channelTypeArray,
      networkTypeArray: [],
      selectedChannel: {},
      selectedService: {},
      serviceList: [],

      trxData: {},

      isApproved: false,
      isEdit: false,
      urlChain: [],
      hosts: [],
      logs: "",
      countryOpt: [],
      selectedCountry: [],
      InputHost: "",
      InputUrlChain: "",
      InputLogs: "",
    };
  },
  mounted() {
    this.init();

    this.$root.$on("insightdetail", async (data) => {
      if (data) {
        this.trxData = data;
        this.isEdit = true;
        // console.log("mounted")
        this.insight_id = data._id;
        this.selectedNetworkType = this.networkTypeArray.find(
          (e) => data.netType == e.id
        );
        this.selectedCountry = this.countryOpt.find(
          (e) => data.country == e.id
        );
        this.selectedService = this.serviceList.find((e) => data.sid == e._id);
        this.basicInfo.bannerFoundUrl = data.bannerFoundUrl;
        this.basicInfo.finalUrl = data.finalUrl;
        this.status = data.status;
        
        if(data._id) {
          await this.getLogs(data._id)
        }
      }
    });
  },

  methods: {

    async saveBasicInfo() {
      if(this.validateBasicInfo()) {
        this.status = 1;
      let payload = {
        insight_id: this.insight_id,
        isEdit: this.isEdit,
        updateObj: {},
      };
      this.basicInfo.country = this.selectedCountry["id"];
      this.basicInfo.netType = this.selectedNetworkType["id"];
      this.basicInfo.sid = this.selectedService["_id"];

      payload.updateObj = this.basicInfo;
      try {
        let response = await InsightService.update(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 1000,
          onComplete: () => {
            // this.$root.$emit("trxlist", {});
            // this.$bvModal.hide("openTrxDetail");
            // this.disable_submit = false;
          },
        });
        if (response.result) {
          let data = response.data;
          let upsertRecord = data.upserted;
      
          if (upsertRecord) {
            this.insight_id = upsertRecord[0]._id;
            this.isEdit = true
          }
        }
      } catch (e) {
        console.error("Error in update insight");
      }
      }
    },

    async removeNetworkType() {
      this.selectedCountry = [];
      await this.changeNetworkType();
    },
    async changeNetworkType() {
      console.log(this.selectedCountry);
      try {
        await this.getNetworkType(this.selectedCountry.id);
      } catch (error) {
        console.log("error in getting net type");
        console.log(error);
      }
    },
    
    addImage() {
      this.images.push(null);
    },
    removeImage(index) {
      this.images.splice(index, 1);
      this.imagePreviewUrls.splice(index, 1);
    },
    previewImage(f, index) {
      const file = f[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagePreviewUrls.splice(index, 1, reader.result);
      };
    },
    
    async init() {
      await this.getNetworkType();
      await this.getCountry();
      let response = await ServiceInfoApi.get();
      if (response.result) {
        this.serviceList = response.data.reverse();
      }
    },
    async getNetworkType(country) {
      let response = await NetworkTypeService.getAllNetworkType(country);
      let networkTypeArray = [];
      for (const [key, value] of Object.entries(response.data)) {
        networkTypeArray.push({ id: key, name: value });
      }
      this.networkTypeArray = networkTypeArray;
    },
    async getCountry() {
      let response = await NetworkTypeService.getCountry();
      this.countryOpt = [];
      response.data.forEach((element) => {
        this.countryOpt.push({ id: element.alpha_3, name: element.name });
      });
    },
    async getLogs(id) {
      this.hosts = [];
      this.urlChain = [];
      this.logs = [];
      let res = await InsightService.insightLogDetail(id);
      // console.log("res", res)
        if(res.result) {
          this.hosts = res.data.hosts || [];
          this.urlChain = res.data.urlChain || [];
          this.logs = res.data.logs || [];
        }
        
    },
    closeModal() {
      this.$root.$emit("insightlist", {});
      this.$bvModal.hide("openInsightDetail");
    },

    async changeTrxDetail() {
      if (this.validateBasicInfo()) {
        if (this.isEdit) {
          await this.editTrxData();
        } else {
          await this.editTrxData();
        }
      }
    },
    async editTrxData() {
      this.disable_submit = true;
      let changeBody = {
        netType: this.selectedNetworkType["id"],
        channel: this.selectedChannel["id"],
        country: this.selectedCountry["id"],
        finalUrl: this.basicInfo.finalUrl,
        bannerFoundUrl: this.basicInfo.bannerFoundUrl,
        hosts: this.hosts,
        urlChain: this.urlChain,
        logs: this.logs,
      };
      if(this.status) {
        changeBody.status = this.status;
      }
      let payload = {
         insight_id: this.insight_id,
         updateObj: changeBody,
      };

      if (this.selectedService) {
        payload.updateObj.sid = this.selectedService._id;
      }

      let response = await InsightService.update(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 1000,
        onComplete: () => {
          this.$root.$emit("insightlist", {});
          this.$bvModal.hide("openInsightDetail");
          this.disable_submit = false;
        },
      });

    },
    

    openImageModal(data) {
      // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`);
      const popupWindow = window.open(
        "",
        "_blank",
        `width=${screen.width},height=${screen.height}`
      );
      popupWindow.document.write(
        `<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`
      );
      popupWindow.document.addEventListener("keydown", function (event) {
        if (event.key === "Escape" || event.keyCode === 27) {
          popupWindow.close();
        }
      });
    },
   
    AddHosts() {
      let urls = this.InputHost.split(",");
      urls.forEach((url) => {
        if (config.validateHost(url) == false) {
          this.$toasted.show("Please enter valid host", {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          return;
        } else {
          this.hosts = [...this.InputHost.split(",")];
        }
      });
    },
    deleteHost(key) {
      if (confirm("Are you sure to delete this host?")) {
        
        this.hosts.splice(key, 1);
        this.InputHost = this.hosts.join(",");
      }
    },
    AddUrlChain() {
      let urls = this.InputUrlChain.split(",");
      urls.forEach((url) => {
        if (config.isValidUrl(url) == false) {
          this.$toasted.show("Please enter valid url", {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          return;
        } else {
           this.urlChain = [...this.InputUrlChain.split(",")];
        }
      });
    },
    deleteUrlChain(key) {
      if (confirm("Are you sure to delete this url?")) {
        this.urlChain.splice(key, 1);
        this.InputUrlChain = this.urlChain.join(",");
      }
    },
    AddLogs() {
      if (this.InputLogs != "") {
        this.logs.push(this.InputLogs);
        this.InputLogs = "";
      }
    },
    deleteLogs(key) {
      if (confirm("Are you sure to delete this log?")) {
        this.logs.splice(key, 1);
      }
    },
    validateBasicInfo() {
      const showToast = (message) => {
        this.$toasted.show(message, {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      };
      console.log("Selected service", this.selectedCountry);
      if (this.selectedService === undefined || this.selectedService === null) {
        showToast("Please select service");
        return false;
      }

      if (this.selectedCountry === undefined || this.selectedCountry === null || this.selectedCountry.length == 0) {
        showToast("Please select country");
        return false;
      }

      if (this.selectedNetworkType === undefined || this.selectedNetworkType === null) {
        showToast("Please select network type");
        return false;
      }
    
      if (
        this.basicInfo.finalUrl == null ||
        this.basicInfo.finalUrl == "" ||
        config.isValidUrl(this.basicInfo.finalUrl) == false
      ) {
        showToast("Please enter valid final url");
        return false;
      }
  
      // if (this.hosts.length == 0) {
      //   showToast("Please enter hosts");
      //   return false;
      // }
      // if (this.urlChain.length == 0) {
      //   showToast("Please enter url chain");
      //   return false;
      // }
      return true;
    },
  },
};
</script>

<style scoped>
.hidden_header {
  display: none;
}
div {
  margin: auto 0;
  width: 100%;
}
.img_custom_style {
  min-height: 200px;
  min-width: 180px;
  max-height: 250px;
  max-width: 250px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 3px solid;
}
</style>