import api from './api'

export default {
  login(data){
    return api.execute(`post`,`/login`, data)
  },
  forgetpwd(data){
    return api.execute(`post`,`/forgetpwd`, data)
  },
  changepwd(data){
    return api.execute(`post`,`/changepwd`, data)
  },
  signup(data){
    return api.execute(`post`,`/signup`,data)
  }
}
