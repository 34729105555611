<template>
    <div>
        <Breadcrumbs
          :title="mainObject.spName + ' - ' + mainObject.title"
          class="text-blue"
          :subtitle="mainObject.category"
        />
        <b-row class="m-2">
            <b-col class="custom-modal-card mb-3">
                <div class="m-2 custom-font-30 text-blue">
                    <span class="m-2">Last 10 Days Report</span>
                    <GChart
                      class="chart-overflow" 
                      id="line-chart"
                      type="LineChart"
                      :data="line_chart.chartData_1"
                      :options="line_chart.options_1"
                    />
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="card-container mb-3">
                <div class="m-2 pt-4 custom-font-24 text-center text-blue custom-modal-card">
                    <span class="m-2">Service Discoveries by Ad Network</span>
                    <GChart class="chart-overflow mb-1" id="pie-chart2" type="PieChart" :data="pie_chart.chartData_1"
                        :options="pie_chart.options_1" />
                </div>
                <div class="m-2 pt-4 custom-font-24 text-center text-blue custom-modal-card">
                    <span class="m-2">Service Discoveries by Channel</span>
                    <GChart class="chart-overflow" id="pie-chart2" type="PieChart" :data="pie_chart.chartData_2"
                        :options="pie_chart.options_2" />
                </div>
            </b-col>
        </b-row>

        <!-- <b-row>
            <b-col>
            <service-info></service-info>
            </b-col>
        </b-row> -->
        <b-card class="custom-modal-card-2">
              <b-row style="max-height: 60vh;overflow-y: scroll;">
            
            <b-col class="custom-modal-card shadow-none mb-3">
                <b-tabs pills class="nav-container m-3" fill>
                    <b-tab title="Destination URL" active>
                        <b-card-text>
                            <b-table
                            sticky-header=true
                            :busy="destURlLoader"
                            :fields="destUrlTableFields"
                            :items="uniqDestUrlData" 
                            borderless
                            stacked="md"
                            head-variant="light"
                            class=" text-left mt-3 custom-table-head custom-font-18"
                            >
                           <template #table-busy>
                            <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                           
                            </div>
                        </template>
                             <template #cell(show_details)="row">
                                <b-button @click="row.toggleDetails" variant="primary" class="mr-2">
                                {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                                </b-button>
                            </template>

                            <template #row-details="row">
                            <b-card>
                                <b-row v-for="(item, index) in row.item.query" :key="index">
                                 
                                    <b-col class="col-md-6 col-6">
                                        <span class="text-blue">{{ index }}</span>
                                    </b-col>
                                    <b-col class="col-md-6 col-6">
                                      <ul>
                                        <li v-for="(item1, index1) in item" :key="index1">{{ item1 }}</li>
                                      </ul>
                                    </b-col>
                                </b-row>
                            <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                                </b-card>
                            </template>
                          <template #cell(domain)="data">
                                <span>{{ data.item.domain }}</span>
                            </template>
                            <template #cell(path)="data">
                                <ul>
                                     <li v-for="(item, index) in data.item.path" :key="index">{{ item }}</li>
                                </ul>
                               
                            </template>

                           <template #cell(params)="data">
                              <ul>
                                     <li v-for="(item, index) in data.item.params" :key="index">{{ item }}</li>
                                </ul>
                            </template>

                             <template #cell(value)="data">
                                <ul>
                                     <li v-for="(item, index) in data.item.value" :key="index">
                                        <ul>
                                        <li v-for="(item1, index1) in item" :key="index1">{{ item1 }}</li>
                                        </ul>
                                     </li>
                                </ul>
                            </template>
                           
                            </b-table>
                        </b-card-text>
                    </b-tab>

                    <b-tab title="Hosts"> 
                        <b-card-text>
                            <b-table 
                            :fields="hostsTableFields"
                            :items="hostsTableData" 
                            borderless
                            :busy="uniqHostLoader"
                            stacked="md"
                            head-variant="light"
                            class="custom-font-18 text-left mt-3 custom-table-head"
                            >
                             <template #table-busy>
                            <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                           
                            </div>
                        </template>
                            <template #cell(domain)="data">
                                <span>{{ data.item.domain }}</span>
                            </template>
                            <template #cell(path)="data">
                                <span>{{ data.item.path }}</span>
                            </template>
                            <template #cell(channels)="data">
                                <span>{{ data.item.channels }}</span>
                            </template>
                            </b-table>
                        </b-card-text>
                    </b-tab>

                    <b-tab title="Unique URLs">
                        <b-card-text>
                            <b-table 
                            :fields="uniqUrlsTableFields"
                            :items="uniqUrlsTableData" 
                            borderless
                            striped
                            :busy="uniqUrlLoader"
                            stacked="md"
                            head-variant="light"
                            class="custom-font-18 text-left mt-3 custom-table-head"
                            >
                             <template #table-busy>
                            <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                           
                            </div>
                        </template>
                            <template #cell(domain)="data">
                                <span>{{ data.item.domain }}</span>
                            </template>
                            </b-table>
                        </b-card-text>
                        </b-tab>
                    </b-tabs>
            </b-col>
        </b-row>

        </b-card>

      <b-card class="custom-modal-card-2">
       
            <b-row class="mb-3">
                <b-col >
                     <span class="custome-title">Discoveries</span>
                </b-col>
                <b-col class="text-right">
                <date-range-picker
                    v-model="dateRange"
                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                    :ranges="customDateRanges"
                    @update="changeTrxTable"
                   ></date-range-picker>
                </b-col>

                <b-col cols="2">
                    <b-input-group label="Per page">
                        <b-form-select v-model="perPage" :options="pageOptions" @change="init()" class="border b-r-5"></b-form-select>      
                        <template #prepend>
                            <b-input-group-text style="background-color: inherit !important; border: none;">Per Page</b-input-group-text>
                        </template>
                    </b-input-group>
                </b-col>
            </b-row>
            
            <b-table class="custom-font-16" stacked="md" :items="trxData" :busy="isBusy" :fields="trxFields" :per-page="perPage"
                :current-page="currentPage" outlined :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection">
                <template #cell(_id)="data">
                    <b class="text-blue cust_text" style="cursor: pointer;" @click.prevent="editTable(data)">{{ data.item._id }}
                    </b>
                </template>
                <template #cell(channel)="data">
                    {{ data.item.channel ? channelTypeArray.find(e => e.id == data.item.channel).name : '' }}
                </template>
                <template #cell(banner)="data">

                    <!-- <b-img fluid :v-if="data.item.banner"  :src="arrayBufferToBase64(data.item.banner)" /> -->
                    <b-img fluid @click="openImageModal(data.item._id, 'banner')" height="120" width="120"
                        :src="getImageBanner(data.item._id)" @error="$event.target.src = (`../no_image.png`);" />

                </template>
                <template #cell(landing)="data">
                    <b-img fluid @click="openImageModal(data.item._id, 'landing')" height="120" width="120"
                        :src="getImageLanding(data.item._id)" @error="$event.target.src = (`../no_image.png`);" />

                </template>
                <template #cell(netType)="data">
                    <!-- {{ data.item.netType ? data.item.netType : '' }}  -->
                    {{ checkNetName(data.item.netType) }}
                </template>
                <template #cell(finalUrl)="data">
                    <a class="cust_url text-blue" :href="data.item.finalUrl" target="_blank"
                        rel="noopener noreferrer">{{ data.item.finalUrl }}</a>
                </template>
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
            </b-table>

            <b-col class="mt-3 p-0 text-right">
                <div class="custom-pagination">
                    <a v-show="currentPage != 1" @click="init('prev')">&laquo; Back</a>
                    <a class="active">{{ currentPage }}</a>
                    <a v-show="trxData.length >= 1" @click="init('next')">Next &raquo;</a>
                </div>
            </b-col>
       
      </b-card>
    </div>
</template>
<script>
import ServicesInfoApiServices from "./../../services/ServiceInfo";
import ServiceInfo from "./Dashboard/ServiceInfo.vue";
import dailiesService from "../../services/DailyServices";
import NetworkTypeService from "../../services/NetworkTypeService";
import config from "../../services/globalLit";
import LineChart from "../components/Charts/LineChart";
import DashboardService from "../../services/DashboardService";
import * as chartConfigs from "@/components/Charts/config";
import globalConfig from "../../services/globalLit";
import { GChart } from "vue-google-charts";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import TranscationService from "../../services/TranscationService";
import serviceInfo from "../../services/ServiceInfo";

export default {
  components: {
    DateRangePicker,
    ServiceInfo,
    LineChart,
    GChart,
  },
  data() {
    const today = new Date();
    return {
      dateRange:{
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)), 
        endDate: today
      },
      customDateRanges: {
        'Today': [today, today],
        'Yesterday': [
          new Date(today.getTime() - 24 * 60 * 60 * 1000),
          new Date(today.getTime() - 24 * 60 * 60 * 1000),
        ],
        'Last 7 Days': [
          new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000),
          today,
        ],
        'Last 30 Days': [
          new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000),
          today,
        ],
        'This Week': [
          new Date(today.getTime() - today.getDay() * 24 * 60 * 60 * 1000),
          today,
        ],
        'Last Week': [
          new Date(today.getTime() - (today.getDay() + 7) * 24 * 60 * 60 * 1000),
          new Date(today.getTime() - today.getDay() * 24 * 60 * 60 * 1000),
        ],
        'Last 3 Weeks': [
          new Date(today.getTime() - 21 * 24 * 60 * 60 * 1000),
          today,
        ],
        'This Month': [
          new Date(today.getFullYear(), today.getMonth(), 1),
          today,
        ],
        'Last Month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        'Last 3 Months': [
          new Date(today.getFullYear(), today.getMonth() - 3, 1),
          today,
        ],
      },
      defaultPayload: {
        edate: new Date().toISOString(),
        sdate: new Date(
          new Date().setDate(new Date().getDate() - 10)
        ).toISOString(),
      },
      destURlLoader: true,
      uniqUrlLoader: false,
      uniqHostLoader: false,
      destUrlTableFields: [
        { key: "domain", label: "Domain" },
        { key: "path", label: "Path" },
        { key: "show_details", label: "Query Params" },
      ],
      uniqDestUrlData: [],
      hostsTableFields: [
        { key: "domain", label: "Domain" },
      ],
      hostsTableData: [],
      uniqUrlsTableFields: [
        { key: "domain", label: "Domain" },
      ],
      uniqUrlsTableData: [],
      sid: "",
      netType: 0,
      data: {},
      sdate: "",
      edate: "",
      channelTypeArray: config.channelTypeArray,
      showModal: false,
      isBusy: false,
      perPage: 100,
      pageOptions: [100, 200, 300, 500],
      currentPage: 1,
      rows: 1,
      trxData: [],
      trxFields: [
        {
          key: "_id",
          label: "ID",
          sortable: true,
        },
        {
          key: "channel",
          label: "Channel",
          sortable: true,
        },
        {
          key: "banner",
          label: "Banner",
        },
        {
          key: "landing",
          label: "Landing",
        },
        {
          key: "netType",
          label: "Network Type",
        },
        {
          key: "finalUrl",
          label: "Final Url",
        },
      ],
      modalimageurl: "",
      networkTypeArray: [],
      sortBy: "_id",
      sortDesc: true,
      sortDirection: "desc",
      bigLineChart: {
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      pie_chart: {
        chartData_1: [[]],
        options_1: {
          title: "",
          is3D: true,
          width: "100%",
          height: 250,
          chartArea: { left: 20, top: 15, right: 0, bottom: 20 },
          legend: {
            position: "right",
            alignment: "center",
            textStyle: {
              color: "#233238",
              fontSize: 14,
            },
          },
          colors: [
            "#8979FF",
            "#FF928A",
            "#3CC3DF",
            "#FFAE4C",
            "#537FF1",
            "#6FD195",
            "#FF6F61",
            "#C4E17F",
            "#FFDC61",
            "#AF7AC5",
          ],
        },
        chartData_2: [[]],
        options_2: {
          title: "",
          is3D: true,
          width: "100%",
          height: 250,
          chartArea: { left: 20, top: 15, right: 0, bottom: 20 },
          legend: {
            position: "right",
            alignment: "center",
            textStyle: {
              color: "#233238",
              fontSize: 14,
            },
          },
          colors: [
            "#8979FF",
            "#FF928A",
            "#3CC3DF",
            "#FFAE4C",
            "#537FF1",
            "#6FD195",
            "#FF6F61",
            "#C4E17F",
            "#FFDC61",
            "#AF7AC5",
          ],
        },
      },
      line_chart: {
        chartData_1: [],
        options_1:  {
          chart: {
             title: '',
             subtitle: ''
          },
          colors: [
            "#8979FF",
            "#FF928A",
            "#3CC3DF",
            "#FFAE4C",
            "#537FF1",
            "#6FD195",
            "#FF6F61",
            "#C4E17F",
            "#FFDC61",
            "#AF7AC5",
          ]
          ,
          height: 500,
          width:'100%',
          curveType: 'function',
          legend: { position: "bottom"},
          chartArea: { left: 50, top: 50, right: 50, bottom: 100 },
          vAxis: {format: '', viewWindow: {min: 0}},
        }
      },
      mainObject: {},
    };
  },
  mounted() {
    this.defaultPayload.country = localStorage.getItem("country");
    
    this.sid = this.$route.query.id;
    if(this.sid) {
      this.defaultPayload.sid = this.sid;
    }
    this.sdate = this.$route.query.sdate;
    this.edate = this.$route.query.edate;
     
    this.init();
    this.initBigChart(0);

  },
  methods: {
    changeTrxTable() {
        this.sdate = this.dateRange.startDate.toISOString();
        this.edate = this.dateRange.endDate.toISOString();
        this.init();
    },
    checkNetName(text) {
      let isFound = this.networkTypeArray.find((e) => e.id == text);
      let name = isFound ? isFound.name : text;
      return name;
    },
    openImageModal(data, type) {
      this.modalimageurl = "";
      switch (type) {
        case "banner":
          this.modalimageurl = this.getImageBanner(data);
          break;
        case "add":
          this.modalimageurl = this.getImageAdd(data);
          break;
        case "landing":
          this.modalimageurl = this.getImageLanding(data);
          break;
      }

      const popupWindow = window.open(
        "",
        "_blank",
        `width=${screen.width},height=${screen.height}`
      );
      popupWindow.document.write(
        `<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`
      );
      popupWindow.document.addEventListener("keydown", function (event) {
        if (event.key === "Escape" || event.keyCode === 27) {
          popupWindow.close();
        }
      });
    },
    getImageLanding(id) {
      return config.getLandingImages + `/${id}`;
    },
    getImageBanner(id) {
      return config.getBannerImages + `/${id}`;
    },
    arrayBufferToBase64(buffer) {
      if (!buffer) return;
      var binary = "";
      var bytes = new Uint8Array(buffer.data);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return binary;
    },

    async init(txt) {
      await this.getNetworkType();
      if (txt == "next") {
        this.currentPage++;
      }
      if (txt == "prev") {
        if (this.currentPage != 1) {
          this.currentPage--;
        }
      }
      this.isBusy = true;
      let payload = {};
      payload.sid = this.sid;
      payload.netType = this.netType;
      payload.page = this.currentPage;
      payload.limit = this.perPage;

      let endDate = new Date();
      let startDate = new Date(endDate);
      startDate.setTime(startDate.getTime() - 10 * 24 * 60 * 60 * 1000);
 

      payload.sdate = this.sdate ? this.sdate : startDate;
      payload.edate = this.edate ? this.edate : endDate;

      let response = await dailiesService.getTrxs(payload);
      if (response.success) {
        this.trxData = response.data;
        this.rows = response.data.length;
      }
      this.isBusy = false;

      let res = await serviceInfo.getServiceInfo({ sid: this.sid });  

      if (res.result) {
        this.mainObject = res.data[0];
        this.mainObject.category = config.categorylist.find(e => e.id == this.mainObject.category).name;
      }
    },
    async getNetworkType() {
      let country = localStorage.getItem("country");
      let response = await NetworkTypeService.getAllNetworkType(country);
      let networkTypeArray = [];
      for (const [key, value] of Object.entries(response.data)) {
        networkTypeArray.push({ id: key, name: value });
      }
      this.networkTypeArray = networkTypeArray;
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    editTable(data) {
      // this.data = Object.assign({}, data.item);
      // this.showModal = true;
      let payload = {
        item: { trxid: data.item._id },
      };

      this.$root.$emit("openTrx", Object.assign({}, payload));
      // this.$bvModal.show("openServiceInfo");
      const url = this.$router.resolve({
        name: "ViewTrx",
        query: {
          trxid: data.item._id,
        },
      }).href;

      window.open(url, "_blank");
    },
    myRowClickHandler(record, index) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      log(record); // This will be the item data for the row
    },
    log(record) {
      console.log(record);
    },
    getArrayDataGoogle(labelArray, recDataObj) {
      let dataArray = [];
      for (let i = 0; i < labelArray.length; i++) {
        let isRecordFound = recDataObj
          ? recDataObj.find((e) => e.date.split("T")[0] === labelArray[i])
          : false;
        if (isRecordFound) {
          dataArray.push(isRecordFound.total);
        } else {
          dataArray.push(0);
        }
      }
      return dataArray;
    },

    async getChennelPieData() {
      let channelPayload = { ...this.defaultPayload };

      let channelStatRes = await ServicesInfoApiServices.getChennel(
        channelPayload
      );
      if (channelStatRes.result) {
        let allData = channelStatRes.data;
        let allChannelKey = Object.keys(allData[0]);
        let pieData = [];
        pieData.push(allChannelKey);
        for (let i = 0; i < allData.length; i++) {
          let rec = allData[i];
          let channelTitle = this.channelTypeArray.find(
            (e) => e.id == rec["_id"]
          )
            ? this.channelTypeArray.find((e) => e.id == rec["_id"]).name
            : rec["_id"].toString();
          pieData.push([channelTitle, rec["total"]]);
        }

        this.pie_chart.chartData_2 = pieData;
      }
    },
    async getAdNetowrksPieData() {
      let adNetworkPayload = { ...this.defaultPayload };

      let adNetworkStatRes = await ServicesInfoApiServices.getAdNetworks(
        adNetworkPayload
      );
      if (adNetworkStatRes.result) {
        let allData = adNetworkStatRes.data;
        let allNetKey = Object.keys(allData[0]);
        let pieData = [];
        pieData.push(allNetKey);
        for (let i = 0; i < allData.length; i++) {
          let rec = allData[i];
          pieData.push([rec["_id"], rec["total"]]);
        }
        this.pie_chart.chartData_1 = pieData;
      }
    },
    async getAdNetworkLineData() {
      let adNetworkPayload = { ...this.defaultPayload };
      let adNetworkStatRes = await ServicesInfoApiServices.getAdNetworksByDate(
        adNetworkPayload
      );

      if (adNetworkStatRes.result) {
        let allData = adNetworkStatRes.data;
        let allAdNetworksKey = Object.keys(allData);

        // Generate labels for the last 8 days
        let today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        let labelArray = [today.toISOString().split("T")[0]];
        for (let i = 0; i < 8; i++) {
          labelArray.push(
            new Date(today.setDate(today.getDate() - 1))
              .toISOString()
              .split("T")[0]
          );
        }
        labelArray = labelArray.reverse();

        // Initialize Google Chart data structure
        let chartData = [];
        let headerRow = ["Date", ...allAdNetworksKey];
        chartData.push(headerRow);

        // Populate data rows
        for (let i = 0; i < labelArray.length; i++) {
          let row = [labelArray[i]];
          for (let j = 0; j < allAdNetworksKey.length; j++) {
            let dataObj = allData[allAdNetworksKey[j]];
            let matchingRecord = dataObj
              ? dataObj.find((e) => e.date.split("T")[0] === labelArray[i])
              : null;
            row.push(matchingRecord ? matchingRecord.total : 0);
          }
          chartData.push(row);
        }

        console.log("chartData", chartData);
        this.line_chart.chartData_1 = chartData;
      }
    },

    async getDestUrls() {
      // /service/desturl
      this.destURlLoader = true;
      let destUrlPayload = {...this.defaultPayload};
      let destUrlRes = await ServicesInfoApiServices.getDestUrls(
        destUrlPayload
      );

      if (destUrlRes.result) {
        this.uniqDestUrlData = destUrlRes.data;
      }
      this.destURlLoader = false;
    },
    async getUniqueHosts() {
        this.uniqHostLoader = true;
      let hostsPayload = {...this.defaultPayload};
      let hostsRes = await ServicesInfoApiServices.getUniqueHosts(hostsPayload);

      if (hostsRes.result) {
        this.hostsTableData = hostsRes.data.map((e) => {
          return {
            domain: e,
          };
        });
      }
        this.uniqHostLoader = false;
    },

    async getUniqueUrls() {
      let hostsPayload = {...this.defaultPayload};
      this.uniqUrlLoader = true
      let hostsRes = await ServicesInfoApiServices.getUniqueUrls(hostsPayload);
      if (hostsRes.result) {
        this.uniqUrlsTableData = hostsRes.data.map((e) => {
          return {
            domain: e,
          };
        });
      }
      this.uniqUrlLoader = false
    },
    getRandomColor() {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return `rgb(${r}, ${g}, ${b})`;
    },
    async initBigChart(index) {
      // await this.getNetworkType();
      this.getAdNetowrksPieData();
      this.getChennelPieData();
      this.getAdNetworkLineData();
      this.getDestUrls();
      this.getUniqueHosts();
      this.getUniqueUrls();
    },
  },
};
</script>
<style scoped>
.modal-header {
  color: #1d75bc;
}

.text-blue {
  color: #274aa6;
}

.table .acClass {
  background-color: red;
}

.table .bTableThStyle {
  background-color: blue !important;
  /* max-width: '100px' !important; */
  text-overflow: ellipsis !important;
}

.table > tbody > tr > td {
  max-width: 350px !important;
  /* text-justify: auto; */
  width: 1000px;
  /* white-space: nowrap; */
  overflow: auto;
  text-overflow: ellipsis;
}

.modal-dialog-1 {
  position: fixed;
  margin: auto;

  height: 100%;
  right: 0px;
}

.modal-content-1 {
  height: 100%;
}

.card-container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 980px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .custom-modal-card {
    width: 100%;
  }
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  cursor: pointer;
}
</style>

<style scoped>
.text-blue {
  color: #274aa6;
}
.text-red {
  color: #f15a29;
  font-weight: lighter;
}
.table .bTableThStyle {
  background-color: #1c75bc !important;
  /* max-width: '100px' !important; */
  text-overflow: ellipsis !important;
}
.table > tbody > tr > td {
  max-width: 350px !important;
  overflow: auto;
  text-overflow: ellipsis;
}
</style>